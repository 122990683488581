<template>
  <b-row>
    <b-col lg="3" xl="3" md="4" sm="6" v-for="(book, index) in books" :key="index" :data="book" class="books">
      <!-- card 1 -->
      <b-card
          :img-src="`https://edu.startupchoikhona.tj/backend${book.photo}`"
          :img-alt="book.name"
          img-top
          no-body
      >

        <b-card-body>
          <b-card-title>{{book.name}}</b-card-title>
          <b-card-text style="max-height: 50px; min-height: 50px" v-html="book.description" class="">
          </b-card-text>
        </b-card-body>
        <b-card-footer class="m-0 p-0">
          <b-button-group class="w-100 m-0 p-0">
            <b-button :variant="'gradient-primary'" class="w-50" @click="showCertificate(`https://edu.startupchoikhona.tj/backend${book.file}`)" v-if="checkPathExtention(`https://edu.startupchoikhona.tj/backend${book.file}`)">
              Просмотреть
            </b-button>
            <a :class="checkPathExtention(`https://edu.startupchoikhona.tj/backend${book.file}`) ? 'w-50' : 'w-100'" class="btn btn-gradient-info" :href="`https://edu.startupchoikhona.tj/backend${book.file}`" attributes-list download>
              Скачать
            </a>
          </b-button-group>
        </b-card-footer>
      </b-card>
    </b-col>
    <b-modal
        id="modal-lg"
        size="lg"
        centered
        ok-only
        ok-title="Закрыть"
        v-model="modalShow"
    >
      <iframe class="w-100 vh-100" :src="activeBookPath"></iframe>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BCardGroup,
  BCardTitle,
  BCardFooter,
  BCardBody,
  BButton,
  BButtonGroup,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BCardGroup,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BButton,
    BButtonGroup,
    BLink,
  },
  data() {
    return {
      books: [],
      modalShow: false,
      activeBookPath: '',
    }
  },
  methods: {
    getBooks() {
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/student/book/index', {
        length: -1,
      })
        .then(response => {
          this.books = response.data.data.items
        })
        .catch()
    },
    showCertificate(path) {
      this.activeBookPath = path
      this.modalShow = true
    },
    checkPathExtention(path) {
      let extPath = path.split('.').pop()
      return extPath !== 'doc' && extPath !== 'docx' && extPath !== 'xls' && extPath !== 'xlsx'
    },
  },
  created() {
    this.getBooks()
  },
}
</script>

<style scoped>
.books img {
  height: 200px;
  object-fit: cover;
}
</style>
